/**
 *  Pages / Index
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Home page
 *
 */

/** Dependencies */
import React from "react";
import { Helmet } from "react-helmet";

/** Local Dependencies */
import { CTA } from "../components/CTA";
import { Head } from "../components/Head";
import { Location } from "../sections/Location";
import { Contact } from "../sections/Contact";
import { Typography } from "../components/Typography";

/** Images */
import backgroundUrl from "../images/plant-1.jpg";
import customerIVCeilingsUrl from "../images/customers/iv-ceilings.png";
import customerDalgopolUrl from "../images/customers/dalgopol.png";
import customerNesebarUrl from "../images/customers/nesebar.png";
import customerHidrostroyUrl from "../images/customers/hidrostroy.png";
import customerDolniChiflikUrl from "../images/customers/dolni-chiflik.png";
import customerPatishtaIMostoveUrl from "../images/customers/patishta-i-mostove.png";
import aboutBackgroundUrl from "../images/plant-2.jpg";
import aboutBackgroundDecorationUrl from "../images/decoration-bottom-right.svg";
import iconBuilding from "../images/icons/building.svg";
import iconMixerTruck from "../images/icons/mixer-truck.svg";

/** Styles */
import * as styles from "./index.module.scss";

const HomePage: React.FC = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Примабилд ООД - Бетонов възел</title>
      <link rel="canonical" href="https://www.primabuild.com/" />
    </Helmet>
    <Head
      backgroundSrc={backgroundUrl}
      title="Производство и доставка на бетонови смеси"
    >
      <Typography
        variant="headlineMedium"
        component="h2"
        className={styles.headText}
      >
        15 години опит в бранша
      </Typography>
      <CTA href="tel:0887277070">Поръчай сега!</CTA>
    </Head>
    <div className={styles.customers}>
      <h3 className={styles.customersTitle}>Част от нашите клиенти</h3>
      <ul className={styles.customersList}>
        <li className={styles.customersListItem}>
          <img src={customerIVCeilingsUrl} alt="Ай Ви Сийлингс" />
        </li>
        <li className={styles.customersListItem}>
          <img src={customerDalgopolUrl} alt="Община Дългопол" />
        </li>
        <li className={styles.customersListItem}>
          <img src={customerNesebarUrl} alt="Община Несебър" />
        </li>
        <li className={styles.customersListItem}>
          <img src={customerHidrostroyUrl} alt="Хидрострой" />
        </li>
        <li className={styles.customersListItem}>
          <img src={customerDolniChiflikUrl} alt="Община Долни Чифлиик" />
        </li>
        <li className={styles.customersListItem}>
          <img src={customerPatishtaIMostoveUrl} alt="Пътища и мостове" />
        </li>
      </ul>
    </div>
    <div className={styles.about}>
      <div className={styles.aboutBackground}>
        <img
          src={aboutBackgroundUrl}
          alt="силози за цимент"
          className={styles.aboutBackgroundImage}
        />
        <img
          src={aboutBackgroundDecorationUrl}
          className={styles.aboutBackgroundDecoration}
          alt="обръч"
        />
      </div>
      <div className={styles.aboutContent}>
        <h4 className={styles.aboutContentDescription}>За нас</h4>
        <h2 className={styles.aboutContentTitle}>Бетонов възел</h2>
        <Typography component="p" variant="bodyMedium">
          Бетонов възел Примабилд е специализиран в производството и доставката
          на бетонови смеси.
        </Typography>
        <ul>
          <li className={styles.aboutContentSectionsSection}>
            <img src={iconBuilding} alt="сграда" />
            <h3 className={styles.aboutContentSectionsSectionTitle}>
              Качествен бетон
            </h3>
            <Typography component="p" variant="bodyMedium">
              Бетонът, произведен от нас е сертифициран и подлежи на външен
              контрол. Автоматизацията вложена в производствения процес е
              гаранция за високочествен продукт.
            </Typography>
          </li>
          <li className={styles.aboutContentSectionsSection}>
            <img src={iconMixerTruck} alt="сграда" />
            <h3 className={styles.aboutContentSectionsSectionTitle}>
              Транспорт и полагане
            </h3>
            <Typography component="p" variant="bodyMedium">
              Разполагаме с бетоновози и бетон-помпи, които доставят и помагат в
              полагането на бетона съгласно изискванията на клиента.
            </Typography>
          </li>
        </ul>
      </div>
    </div>
    <Contact />
    <Location />
  </>
);

export default HomePage;
