// extracted by mini-css-extract-plugin
export var about = "index-module--about--6cOvp";
export var aboutBackground = "index-module--aboutBackground--2IxKL";
export var aboutBackgroundDecoration = "index-module--aboutBackgroundDecoration--UC91s";
export var aboutBackgroundImage = "index-module--aboutBackgroundImage--ejT7D";
export var aboutContent = "index-module--aboutContent--v18ba";
export var aboutContentDescription = "index-module--aboutContentDescription--quzG7";
export var aboutContentSectionsSection = "index-module--aboutContentSectionsSection--cfc4S";
export var aboutContentSectionsSectionTitle = "index-module--aboutContentSectionsSectionTitle--+Cj36";
export var aboutContentTitle = "index-module--aboutContentTitle--CneIX";
export var customers = "index-module--customers--TDA8u";
export var customersList = "index-module--customersList--DQrzb";
export var customersListItem = "index-module--customersListItem--MDN8d";
export var customersTitle = "index-module--customersTitle--xqnrR";
export var headText = "index-module--headText--QPLOG";